

































import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "AdCTAForm",
  data: () => ({
    valid: false,
    callToActionText: "",
    shareButtonText: "",
  }),
  computed: {
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
  },
  created() {
    this.resetForm();
  },
  methods: {
    validateForm() {
      (
        this.$refs.discountForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      this.$emit("data-valid", {
        shareButtonText: this.shareButtonText,
        callToActionText: this.callToActionText,
      });
    },
    resetForm() {
      this.callToActionText = "";
      this.shareButtonText = "";

      (
        this.$refs.discountForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
});
