











































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import advertisementStoreModule from "@/store/modules/advertisement";
import { Business, Role, Advertisement, User } from "@/types";
import AdEditForm from "@/components/marketing/ad/AdEditForm.vue";
import AdCallToActionForm from "@/components/marketing/ad/AdCallToActionForm.vue";
import AdDateForm from "@/components/marketing/ad/AdDateForm.vue";
import AdPreview from "@/components/marketing/ad/AdPreview.vue";

const { mapActions: advertisementActions } = createNamespacedHelpers("AD_");

export default Vue.extend<any, any, any, any>({
  name: "CreateAdvertisement",
  components: { AdEditForm, AdCallToActionForm, AdDateForm, AdPreview },
  data() {
    return {
      e1: 1,
      advertisement: undefined as undefined | Advertisement,
    };
  },
  computed: {
    user(): User {
      return this.$store.getters.user;
    },
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...advertisementActions(["createAdvertisement"]),
    nextStep(n: number) {
      if (n === 4) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    prevStep(n: number) {
      if (n === 4) {
        this.e1 = 1;
      } else {
        this.e1 = n - 1;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAd(ad: any) {
      this.advertisement = { ...this.advertisement, ...ad };
      this.e1 += 1;
    },
    saveAdvertisement() {
      const data = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ad: any = this.advertisement;

      if (!ad) return;

      data.append("businessId", (this.role.business as Business)._id);
      data.append("title", ad.title);
      data.append("description", ad.description);
      data.append("detailedDescription", ad.detailedDescription);
      data.append("callToActionText", ad.callToActionText);
      data.append("shareButtonText", ad.shareButtonText);
      data.append("startsOn", ad.startsOn.toISOString());
      data.append("expiresOn", ad.expiresOn.toISOString());

      if (ad.file) {
        data.append("file", ad.file);
      }

      this.createAdvertisement(data).then((advertisement) => {
        if (advertisement) {
          this.$router.push("/marketing");
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("AD_")) {
      this.$store.registerModule("AD_", advertisementStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("AD_");
  },
});
