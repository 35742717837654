





























import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "AdPreview",
  props: {
    advertisement: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    imageSrc: "",
  }),
  filters: {
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("Do MMM YYYY");
    },
  },
  created() {
    if (this.advertisement.file)
      this.imageSrc = URL.createObjectURL(this.advertisement.file);
  },
});
