











































































































import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "AdDateForm",
  data: () => ({
    valid: false,
    expiresOn: "",
    startsOn: "",
    startDateMenu: false,
    startDateFormatted: "",
    expiryDateFormatted: "",
    expiryDateMenu: "",
    expiryTime: "",
    startTime: "",
  }),
  computed: {
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
  },
  created() {
    this.resetForm();
  },
  methods: {
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    parseDate(date: string) {
      if (!date) return null;
      let d: string | string[] = date.split("/");

      d = `${d[2]}-${d[1]}-${d[0]}`;

      return moment(d).tz(tz).format("YYYY-MM-DD");
    },
    validateForm() {
      (
        this.$refs.adDateForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      let startDate: string | string[] = this.startDateFormatted;

      startDate = (startDate as string).split("/");

      startDate = `${startDate[1]}-${startDate[0]}-${startDate[2]}`;
      const startsOn = new Date(`${startDate} ${this.startTime}`);

      let expiryDate: string | string[] = this.expiryDateFormatted;

      expiryDate = (expiryDate as string).split("/");

      expiryDate = `${expiryDate[1]}-${expiryDate[0]}-${expiryDate[2]}`;
      const expiresOn = new Date(`${expiryDate} ${this.expiryTime}`);

      this.$emit("data-valid", {
        expiresOn,
        startsOn,
      });
    },
    resetForm() {
      this.expiresOn = "";
      this.startsOn = "";

      (
        this.$refs.adDateForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
});
