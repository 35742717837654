
























































































import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "AdEditForm",
  data: () => ({
    valid: false,
    accept: "image/png, image/jpeg, image.jpg",
    title: "",
    description: "",
    detailedDescription: "",
    image: undefined as undefined | File,
    imageSnackbar: false,
  }),
  computed: {
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
  },
  created() {
    this.resetForm();
  },
  methods: {
    upload(files: FileList) {
      this.image = files[0];
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
    validateForm() {
      (
        this.$refs.adForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      this.imageSnackbar = this.image ? false : true;

      if (this.imageSnackbar) return;

      const payload = {
        title: this.title,
        description: this.description,
        detailedDescription: this.detailedDescription,
        file: this.image,
      };

      this.$emit("data-valid", payload);
    },
    resetForm() {
      this.title = "";
      this.description = "";
      this.detailedDescription = "";

      (
        this.$refs.adForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
  },
});
